import { OverlayProvider } from "@components/contexts/overlayProvider";
import { ThemeProvider } from "@components/contexts/themeProvider";
import { processEnv } from "@lib/processEnv";
import "@styles/colors.css";
import "@styles/common.css";
import "@styles/dark-mode.css";
import "@styles/emojipickercustom.css";
import "@styles/fonts.css";
import "@styles/index.css";
import "@styles/landing-animation.css";
import "@styles/prism.css";
import "@styles/screen-fixings.css";
import "@styles/screen.css";
import "@styles/toc.css";
import "@styles/emojipickercustom.css";
import "@styles/style-min.css";
import "@styles/webinar.css";
import "@styles/fader.css";
import "@styles/lineloader.css";
import { urlTracker } from "@utils/campaign-tracker";
import { AppProps } from "next/app";
import CookieConsent, { Cookies } from "react-cookie-consent";

import React, { useEffect, useState } from "react";
import SnackbarProvider from "react-simple-snackbar";

import { LuX } from "react-icons/lu";

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
    const [showCookieBanner, setShowCookieBanner] = useState<boolean>(true);

    const handleClose = () => {
        Cookies.set("consentCookie", "true", { expires: 30 });
        setShowCookieBanner(false);
    };

    useEffect(() => {
        const { cmsData } = pageProps || { cmsData: null };
        const { bodyClass } = cmsData || { bodyClass: null };
        const htmlTag = document.querySelector("html") as HTMLElement;
        htmlTag.className = bodyClass ? "casper" : "home";
    });

    useEffect(() => {
        urlTracker();
    }, []);

    useEffect(() => {
        const isCookieConsent = Cookies.get("consentCookie");
        if (isCookieConsent) {
            setShowCookieBanner(false);
        }
    }, []);

    return (
        <ThemeProvider {...processEnv.darkMode}>
            <OverlayProvider>
                <SnackbarProvider>
                    <Component {...pageProps} />
                </SnackbarProvider>
            </OverlayProvider>
            {showCookieBanner && (
                <CookieConsent buttonText="" hideOnAccept={false} onAccept={() => setShowCookieBanner(false)} cookieName="consentCookie" location="bottom">
                    <div className="text-black font-normal text-base flex items-center justify-between">
                        <p className="text-sm">
                            Cookies are needed to deliver our services. By using our website, you agree to our use of cookies.{" "}
                            <a href="/privacy-policy" className="text-blue-600 w-full">
                                Learn more
                            </a>
                        </p>
                        <div className="self-start md:self-center flex">
                            <button className="text-gray-700 font-medium text-md" onClick={() => handleClose()}>
                                <LuX />
                            </button>
                        </div>
                    </div>
                </CookieConsent>
            )}
        </ThemeProvider>
    );
};

export default App;
